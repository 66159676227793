import { IMessageDTO as IPostedMessageDTO } from "./InteractionDTO";
import { IPaginatedDTO } from "./PaginatedDTO";

export enum MessageFunctionOutcome {
  SUCCESS = "success",
  ERROR = "error",
  NOT_EXECUTED = "not-executed"
}

interface IMessageFunction {
  type: string;
  outcome?: MessageFunctionOutcome;
}

interface IChangeAgentFunction extends IMessageFunction {
  agentName: string;
  type: string;
  reason?: string;
}

interface IUpdatePreferencesFunction extends IMessageFunction {
  details: Record<string, any>;
  type: string;
}

interface IStartApplicationFunction extends IMessageFunction {
  candidateId: string;
  type: string;
}

export enum Agent {
  ALIGNMENT = "alignment",
  HUMAN = "human",
  SUMMARIZER = "summarizer",
  DYNAMIC = "dynamic",
  ORCHESTRATOR_EMAIL = "orchestrator-email",
  ORCHESTRATOR_MESSENGER = "orchestrator-messenger",
  REQUEST_SCREENER = "request-screener",
  LANGUAGE_DETECTOR = "language-detector",
  VERIFIER = "verifier",
  PLANNER = "planner"
}

export interface ITokensUsedDTO {
  inputTokens: number;
  outputTokens: number;
}

export enum ThreadEnvironment {
  TEST = "test",
  PRODUCTION = "production"
}

export interface IAgentConfigurationDTO {
  disabled: boolean,
  disabledFunctions: string[],
  name: Agent,
  promptVersion?: number
};

export interface IThreadConfigurationDTO {
  agents: IAgentConfigurationDTO[],
  environment: ThreadEnvironment
};

export type EmmaFunction = IChangeAgentFunction | IUpdatePreferencesFunction | IStartApplicationFunction

export interface IThreadDTO {
  id: number;
  personGgId: string;
  agent: Agent;
  isBanned: boolean;
  configuration: IThreadConfigurationDTO;
}

export interface IUpdateThreadDTO {
  agent?: Agent;
  isBanned?: boolean;
}

export interface IMessageDTO {
  id: number;
  threadId: number;
  messageRefId: string;
  source: string
  agent?: Agent;
  promptId?: number;
  cost: number;
  userMessage: string;
  assistantResponse?: string;
  tokensUsed?: ITokensUsedDTO;
  thread?: IThreadDTO;
  functions: EmmaFunction[];
  hasEvaluation: boolean;
  created: string;
}

export interface IFilterMessageDTO {
  messageRefIds?: string[];
  messageSources?: string[];
  userGgIds?: string[];
  messageIds?: number[];
  agents?: Agent[];
  messageStatuses?: string[];
  excludeEvaluated?: boolean;
  threadEnvironment?: string;
  after?: string;
  sort?: "asc" | "desc";
};

export class PaginatedMessageDTO implements IPaginatedDTO<IMessageDTO> {
  page: number = 1;
  total: number;
  elements: IMessageDTO[];
  pageSize: number;
  pageLength: number;

  constructor(data: IPaginatedDTO<IMessageDTO>) {
    this.page = data.page || 1;
    this.total = data.total;
    this.elements = data.elements;
    this.pageSize = data.elements.length || 10;
    this.pageLength = Math.ceil(data.total / this.pageSize);
  };
};

export enum CaseIdentifiers {
  ACTIVE_SEEKING = "active-seeking",
  PASSIVE_SEEKING = "passive-seeking",
  HAS_JOB = "has-job",
  NO_SEEKING_JOB = "no-seeking-job",
  DONT_REACH_OUT = "dont-reach-out",
  BUG_REPORTED = "bug-reported",
  INTERESTED_TO_APPLY = "interested-to-apply",
  NEXT_STEPS_INFO = "next-steps-info",
  ALREADY_APPLIED = "already-applied",
  UNINTERESTED_TO_APPLY = "uninterested-to-apply",
  NOT_ALIGNED_JOB = "not-aligned-job",
  OTHER_JOBS = "other-jobs",
  JOB_INFO = "job-info",
  QUESTIONS_ABOUT_SQ_LINK = "questions-about-sq-link",
  WILL_DO = "will-do",
  SCREENING_QUESTIONS_DONE = "screening-questions-done",
  CONTACT_COMPANY_THEMSELVES = "contact-company-themselves",
  MEETING_REQUEST = "meeting-request",
  AUTO_MESSAGE_RECEIVED = "auto-message-received",
  INTEREST_JOB_TYPE = "interest-job-type",
  SKILLS_INFO = "skills-info",
  LANG_FLUENCY = "lang-fluency",
  JOB_COMPENSATION = "job-compensation",
  USER_COMPENSATION_INFO = "user-compensation-info",
  USER_LOCATION_INFO = "user-location-info",
  JOB_FEEDBACK = "job-feedback",
  OPT_IN_WHATSAPP_NO = "opt-in-wa-no",
  OPT_IN_WHATSAPP_YES = "opt-in-wa-yes",
  POTENTIAL_PARTNERSHIP = "potential-partnership",
  PROFILE_COMPLETED_DONE = "profile-completed-done",
  REFERRING = "referring",
  REMOTE_WORK_YES = "remote-work-yes",
  SORRY_FOR_LATE_REPLY = "sorry-for-late-reply",
  THANKS_ACK = "thanks-ack",
  QUESTION_ABOUT_EMMA = "question-about-emma",
  GREETING = "greeting",
  USER_TALENT_SEEKER = "user-talent-seeker",
  WHY_CONTACT = "why-contact",
  CONTACT_INFORMATION_PROVIDED = "contact-information-provided",
  WAITING_FOR_RESPONSE = "waiting-for-response",
  TALK_ABOUT_THEM = "talk-about-them",
  ASK_ADVICE = "ask-advice",
  UNHAPPY_USER = "unhappy-user",
  REMOTE_WORK_NO = "remote-work-no",
  QUESTION_APPLICATION = "question-application",
  ANOTHER_CHANNEL = "another-channel",
  REQUEST_JOB_SUGGESTIONS = "request-job-suggestions",
  EMPLOYMENT_SERVICES_INFORMATION_REQUESTED = "employment-services-information-requested",
  VERIFIER_PROVIDED = "verifier-provided",
  ACCOUNT_DELETION_REQUEST = "account-deletion-request",
  BEHAVIORAL_TRAITS_QUESTIONNAIRE = "behavioral-traits-questionnaire"
}

export interface IFilterPromptDTO {
  agents?: Agent[];
  caseIdentifiers?: CaseIdentifiers[]
}

export interface IPromptDTO {
  id: number;
  agent?: Agent;
  caseIdentifier?: CaseIdentifiers;
  version: number;
  text: string;
  enabledEnvironments: ThreadEnvironment[];
  tools: string[];
  created: string;
  deactivated?: string
}

export interface IUpdatePromptDTO {
  text?: string;
  enabledEnvironments?: ThreadEnvironment[];
}

export class PaginatedPromptDTO implements IPaginatedDTO<IPromptDTO> {
  page: number = 1;
  total: number;
  elements: IPromptDTO[];
  pageSize: number;
  pageLength: number;

  constructor(data: IPaginatedDTO<IPromptDTO>) {
    this.page = data.page || 1;
    this.total = data.total;
    this.elements = data.elements;
    this.pageSize = data.elements.length || 10;
    this.pageLength = Math.ceil(data.total / data.elements.length);
  };
};

export const defaultThreadConfiguration = (): IThreadConfigurationDTO => ({
  agents: [
    {
      disabled: false,
      disabledFunctions: [],
      name: Agent.DYNAMIC
    },
    {
      disabled: false,
      disabledFunctions: [],
      name: Agent.HUMAN
    }
  ],
  environment: ThreadEnvironment.TEST
});

export interface IMessageWithConfigurationDTO {
  message: IPostedMessageDTO,
  threadConfiguration: IThreadConfigurationDTO
};

export interface IProcessedMessageResultDTO {
  id?: number
};

export enum MessageProcessingStatus {
  COMPLETED = "completed"
}

export interface IProximaFirebaseMessageDTO {
  id: number,
  processingStatus: MessageProcessingStatus
}

export interface ISaveRequestDTO {
  caseIdentifier: string,
  phrase: string,
  topic: string
};

export interface IUpdateRequestDTO {
  caseIdentifier?: string,
  phrase?: string,
  topic?: string
};

export interface IFilterRequestsDTO {
  caseIdentifiers?: CaseIdentifiers[]
};

export interface IRequestDTO {
  id: number,
  caseIdentifier: CaseIdentifiers,
  phrase: string,
  topic: string,
  isEmbedded: boolean,
  created: string
};

export class PaginatedRequestDTO implements IPaginatedDTO<IRequestDTO> {
  page: number = 1;
  total: number;
  elements: IRequestDTO[];
  pageSize: number;
  pageLength: number;

  constructor(data: IPaginatedDTO<IRequestDTO>) {
    this.page = data.page || 1;
    this.total = data.total;
    this.elements = data.elements;
    this.pageSize = data.elements.length || 10;
    this.pageLength = Math.ceil(data.total / data.elements.length);
  };
};
